import React from 'react';
import { Container } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Row } from "react-bootstrap";

function ModuleDesign() {
    return (
        <div className="bg-module d-flex align-items-center">
            <Container>
                <Row>
                    <Col lg="6" className="order-2">
                        <h2 className="lh-1">Te ofrecemos</h2>
                        <h1 className="title-slider">Soluciones Modulares</h1>
                    </Col>
                    <Col lg="6">
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ModuleDesign; 