import { Col } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { Button } from "react-bootstrap";
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';




export const ContactUs = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_o7byg3j', 'template_5sbkwy6', form.current, 'XcX-JsLQ4DwiraLcl')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
        e.target.reset()
        alert("Mensaje enviado correctamente");
    };

    return (
        <Col lg="7">

            <Form className='color-lightgray' ref={form} id='SendMessage' onSubmit={sendEmail}>
                <Row>

                    <Col md="12">
                        <Form.Group className="mb-3">
                            <Form.Label>Nombre Completo</Form.Label>
                            <Form.Control type="text" placeholder="Escribe tu nombre completo" name='name' />
                        </Form.Group>
                    </Col>

                    <Col md="6">
                        <Form.Group className="mb-3">
                            <Form.Label>Correo electrónico</Form.Label>
                            <Form.Control type="email" placeholder="name@example.com" name='mail' />
                        </Form.Group>
                    </Col>

                    <Col md="6">
                        <Form.Group className="mb-3">
                            <Form.Label>Teléfono</Form.Label>
                            <Form.Control type="phone" placeholder="+52 55 4902 0293" name='phone' />
                        </Form.Group>
                    </Col>

                    <Col md="12">
                        <Form.Group className="mb-3">
                            <Form.Label>Mensaje</Form.Label>
                            <Form.Control as="textarea" rows={3} name='message' />

                        </Form.Group>
                    </Col>

                    <Col md="12">
                        <div className='d-flex align-items-end'>
                            <Form.Check name='privacy' value='Acepto términos y condiciones' />
                            <small className='fw-bold mx-3'>Acepto los <span className='color-onbase'>Términos y Condiciones</span> y he leído el <span className='color-onbase'>Aviso de Privacidad</span></small>
                        </div>
                    </Col>
                    <Col md="12">
                        <Button className='w-100 my-5' type='submit' value='Send' variant="outline-primary">Enviar Mensaje</Button>
                    </Col>
                </Row>
            </Form>

        </Col>);
};

export default ContactUs;
