import React from 'react';
import { Container } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Row } from "react-bootstrap";

function Methodology () {
    return (
        <div className="bg-method d-flex align-items-center my-5">
            <Container>
                <Row>
                    <Col lg="8">
                        <h1 className="title-slider">Métodos eficientes</h1>
                        <h2 className="lh-1">para llevar a cabo tu proyecto</h2>
                    </Col>
                    <Col lg="4">
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Methodology;