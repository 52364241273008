import './scss/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'swiper/css';
import Header from './Header';
import Slider from './Slider'
import AboutUs from './AboutUs';
import Dreams from './Dreams';
import Services from './Services';
import Methodology from './Methodology';
import Advantage from './Advantage';
import ModuleDesign from './ModuleDesign';
import Contact from './Contact';
import ScrollButton from './Components/ButtonScrollTop';
import SwiperCore, { Autoplay } from 'swiper';


function App() {
  SwiperCore.use([Autoplay]);
  return (
    <div>
      <Header />
      <Slider />
      <AboutUs />
      <Dreams />
      <Services />
      <Methodology />
      <Advantage />
      <ModuleDesign />
      <Contact />
      <ScrollButton />
    </div>
  );
}

export default App;
