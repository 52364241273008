import { Container } from "react-bootstrap";
import { Col } from "react-bootstrap";

function SliderInfo() {
    return (
        <Col lg="7">
            <div className='text-light slider-info' expand="lg">
                <Container>
                    <h2>¡Hola!</h2>
                    <h1 className="title-slider">Somos 85media</h1>
                </Container>
            </div>
        </Col>
    )
}

export default SliderInfo;