import React from 'react';
import { Navbar } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import { Nav } from 'react-bootstrap';
import logo from './images/logo-w.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp, faFacebookF, faTwitter, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';



function Header() {
  return (
    <div>
      <Navbar bg="base" variant='dark' fixed='top' expand="lg" >
        <Container expand="lg">
          
          <Navbar.Brand href="#home" className='mr-5'>
            <img
              src= {logo}
              width="40"
              height="auto"
              className="d-inline-block align-top "
              alt="85media logo"
            />
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="responsive-navbar-nav" className='color-lightgray' />
         
          <Navbar.Collapse>
            <Nav className="me-auto">
              <Nav.Link className='link-light' href="#home">Inicio</Nav.Link>
              <Nav.Link className='link-light' href="#aboutus">Nosotros</Nav.Link>
              <Nav.Link className='link-light' href="#services">Servicios</Nav.Link>
              <Nav.Link className='link-light' href="#benefits">Ventajas y Beneficios</Nav.Link>
              <Nav.Link className='link-light' href="#contact">Contacto</Nav.Link>
            </Nav>

            <div className='justify-content-end my-2'>
              <a target='_blank' rel="noopener noreferrer" href="https://wa.me/527298624995/?text=Hola%20me%20gustaría%20obtener%20información"><FontAwesomeIcon icon={faWhatsapp} className="mx-2"/></a>
              <a target='_blank' rel="noopener noreferrer" href="https://www.facebook.com/85mediamx"><FontAwesomeIcon icon={faFacebookF} className="mx-2" /></a>
              <a target='_blank' rel="noopener noreferrer" href="https://www.twitter.com/85mediamx"><FontAwesomeIcon icon={faTwitter} className="mx-2" /></a>
              <a target='_blank' rel="noopener noreferrer" href="https://www.instagram.com/85mediamx"><FontAwesomeIcon icon={faInstagram} className="mx-2" /></a>
              <a target='_blank' rel="noopener noreferrer" href="https://www.tiktok.com/@85mediamx"><FontAwesomeIcon icon={faTiktok} className="mx-2" /></a>
            </div>
            

          </Navbar.Collapse>

          
          

          
          
        </Container>
      </Navbar>

      
    </div>
  );
}

export default Header;