import React from 'react';
import { Container } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Row } from "react-bootstrap";
import AdvantageImg from './images/Advantage.png';

function Advantage() {
    return (
        <Container expand="lg" id='benefits'>
            <Row className="my-5 py-5">
                <Col lg="5" className="d-flex justify-content-start order-2">

                    <img
                        src={AdvantageImg}
                        className="img-fluid img-shape my-4" alt="Ventajas" />
                </Col>
                <Col lg="7" className="order-1">
                    <h2>Ventajas y <span className="fw-bold">Beneficios</span></h2>
                    <p>Confianza, Puntualidad y Compromiso con tus proyectos. Establecemos un vínculo con nuestros clientes, creemos que un buena retroalimentación entre el cliente y el prestador de servicios es vital para el desarrollo de un proyecto de calidad.</p>

                    <p>Nos adaptamos a tus necesidades reales, y ofrecemos procesos y servicios óptimos y eficientes para nuestros clientes y sus usuarios.</p>

                    <p>A las PYMES: Asesoramiento en publicidad y marketing.
                    Entendemos su problemática y le ofrecemos soluciones reales ajustadas a sus verdaderas necesidades, accediendo a servicios profesionales de comunicación, diseño y marketing.</p>

                    <p>A los Departamentos de Comunicación y Marketing: Un equipo de profesionales comprometidos con los objetivos y plazos de sus campañas. Realizando tanto el diseño y maquetación de los folletos/catálogos y elementos de comunicación (con precios y plazos ajustados), como la creación y gestión de campañas de marketing directo, microsites, webs.</p>
                </Col>
            </Row>
        </Container>
    );
}

export default Advantage;