import { Container } from "react-bootstrap";
import { Col } from "react-bootstrap";

function SliderInfo2() {
    return (
        <Col sm={{span:7, offset:5}} className="d-flex justify-content-end">
            <div className='text-light slider-info-2 w-100' expand="lg">
                <Container>
                            <h2>Hacemos que tus ideas</h2>
                            <h1 className="title-slider">Se vuelvan realidad</h1>
                </Container>
            </div>
        </Col>
    )
}

export default SliderInfo2;