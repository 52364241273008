import React from 'react';
import { Container } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Row } from "react-bootstrap";
import Logo from './images/logo-w.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp, faFacebookF, faTwitter, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';
import ContactUs from './Components/ContactUs';




function Contact() {

    return (
        <div>
            <Container expand="lg" id='contact'>
                <Row className="mt-5">
                    <h2>Envíanos un <span className="fw-bold">mensaje</span></h2>
                </Row>
                <Row className='my-5'>
                    <Col lg="5" className="color-lightgray">

                        <img
                            src={Logo}
                            className="img-fluid my-4"
                            width="50px" alt="85mediamx" />

                       

                        <div className='d-flex my-3 align-items-center'>
                            <FontAwesomeIcon icon={faWhatsapp} className="mx-2" />
                            +52 729 862 4995
                        </div>

                        <div className='d-flex my-3 align-items-center'>
                            <FontAwesomeIcon icon={faEnvelope} className="mx-2" />
                            mario@85media.mx
                        </div>

                        <div className='my-3'>
                            <a target='_blank' rel="noopener noreferrer" href="https://www.facebook.com/85mediamx"><FontAwesomeIcon icon={faFacebookF} className="mx-2" /></a>
                            <a target='_blank' rel="noopener noreferrer" href="https://www.twitter.com/85mediamx"><FontAwesomeIcon icon={faTwitter} className="mx-2" /></a>
                            <a target='_blank' rel="noopener noreferrer" href="https://www.instagram.com/85mediamx"><FontAwesomeIcon icon={faInstagram} className="mx-2" /></a>
                            <a target='_blank' rel="noopener noreferrer" href="https://www.tiktok.com/@85mediamx"><FontAwesomeIcon icon={faTiktok} className="mx-2" /></a>
                        </div>

                    </Col>

                    {/* Aqui va el componente */}
                    <ContactUs />
                  
                </Row>
            </Container>
            <div className='mt-5'>
                <Col lg="12" className='d-flex justify-content-center bg-onbase py-3'>
                    <small className='color-lightgray fw-bold px-5 text-center'>85mediamx® Derechos Reservados 2022 . Aviso de Privacidad, Términos y Condiciones</small>
                </Col>
            </div>
        </div>
    );
}

export default Contact;