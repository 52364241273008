import { Container } from "react-bootstrap";
import { Col } from "react-bootstrap";

function SliderInfo3() {
    return (
        <Col lg="7">
            <div className='text-light slider-info' expand="lg">
                <Container>
                    <h2>Te ofrecemos</h2>
                    <h1 className="title-slider">Soluciones Modulares</h1>
                </Container>
            </div>
        </Col>
    )
}

export default SliderInfo3;