import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import SliderInfo from './Components/SliderInfo';
import SliderInfo2 from './Components/SliderInfo2';
import SliderInfo3 from './Components/SliderInfo3';
import { Navigation, Pagination } from 'swiper';



function Slider() {
  return (
    <Swiper
      id='homeSlider'
      loop= {true}
      modules={[Navigation, Pagination]} 
      navigation
      pagination={{ clickable: true }}
      spaceBetween={10}
      slidesPerView={1}
      autoplay={{ delay: 3000 }}
      onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}
    >
      <SwiperSlide className='bg-slide d-flex align-items-center'>
          <SliderInfo></SliderInfo>
      </SwiperSlide>

      <SwiperSlide className='bg-slide-2 d-flex align-items-center'>
          <SliderInfo2></SliderInfo2>
      </SwiperSlide>

      <SwiperSlide className='bg-slide-3 d-flex align-items-center'>
          <SliderInfo3></SliderInfo3>
      </SwiperSlide>
     
    </Swiper>
  );
};

export default Slider