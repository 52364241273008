import React from 'react';
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import Tick from './images/tick.svg';
import Identity from './images/identity.svg';
import Social from './images/social.svg';
import Design from './images/design.svg';
import OutOfHome from './images/ooh.svg';
import Video from './images/video.svg';
import Event from './images/event.svg';

function Services () {
    return (
        <Container id='services'>
            <Row>
            <h2 className='text-center mt-5'>Nuestros <span className="fw-bold">Servicios</span></h2>
            </Row>
            <Row>
                <Col md="4">
                    <Card className="card-transparent my-5">
                        <Card.Body>
                        <div className='tick d-flex justify-content-end my-2'><img src={ Tick } alt='tickbtn'/></div>
                        <div className='d-flex justify-content-center'><img src= { Identity } width='100px' className='img-fluid' alt="logotype"/></div>
                        <Card.Title className='text-center my-4'>Identidad <span className='fw-bold'>Corporativa</span></Card.Title>
                        </Card.Body>
                    </Card>
                </Col>

                <Col md="4">
                    <Card className="card-transparent my-5">
                        <Card.Body>
                        <div className='tick d-flex justify-content-end my-2'><img src={ Tick } alt='tickbtn'/></div>
                        <div className='d-flex justify-content-center'><img src= { Social } width='100px' className='img-fluid' alt="marketing"/></div>
                        <Card.Title className='text-center my-4'>Marketing <span className='fw-bold'>Digital</span></Card.Title>
                        </Card.Body>
                    </Card>
                </Col>

                <Col md="4">
                    <Card className="card-transparent my-5">
                        <Card.Body>
                        <div className='tick d-flex justify-content-end my-2'><img src={ Tick } alt='tickbtn'/></div>
                        <div className='d-flex justify-content-center'><img src= { Design } width='100px' className='img-fluid' alt="web design"/></div>
                        <Card.Title className='text-center my-4'>Diseño y <span className='fw-bold'>Desarrollo Web</span></Card.Title>
                        </Card.Body>
                    </Card>
                </Col>


                <Col md="4">
                    <Card className="card-transparent my-5">
                        <Card.Body>
                        <div className='tick d-flex justify-content-end my-2'><img src={ Tick } alt='tickbtn'/></div>
                        <div className='d-flex justify-content-center'><img src= { OutOfHome } width='100px' className='img-fluid' alt="media ooh"/></div>
                        <Card.Title className='text-center my-4'>Planificación de <span className='fw-bold'>Medios</span></Card.Title>
                        </Card.Body>
                    </Card>
                </Col>

                <Col md="4">
                    <Card className="card-transparent my-5">
                        <Card.Body>
                        <div className='tick d-flex justify-content-end my-2'><img src={ Tick } alt='tickbtn'/></div>
                        <div className='d-flex justify-content-center'><img src= { Video } width='100px' className='img-fluid' alt="video"/></div>
                        <Card.Title className='text-center my-4'>Producción <span className='fw-bold'>Audiovisual</span></Card.Title>
                        </Card.Body>
                    </Card>
                </Col>

                <Col md="4">
                    <Card className="card-transparent my-5">
                        <Card.Body>
                        <div className='tick d-flex justify-content-end my-2'><img src={ Tick } alt='tickbtn'/></div>
                        <div className='d-flex justify-content-center'><img src= { Event } width='100px' className='img-fluid' alt="event"/></div>
                        <Card.Title className='text-center my-4'>Logística de <span className='fw-bold'>Eventos</span></Card.Title>
                        </Card.Body>
                    </Card>
                </Col>


            </Row>
        </Container>
    )
}

export default Services;