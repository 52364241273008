import React from 'react';
import { Container } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Row } from "react-bootstrap";
import AbouUsImg from './images/aboutUs.png';

function AboutUs() {
    return (
        <Container expand="lg" id='aboutus'>
            <Row className="my-5 py-5">
                <Col lg="5" className="d-flex justify-content-end">

                    <img
                        src={AbouUsImg}
                        className="img-fluid img-shape my-4" alt="Sobre Nosotros" />
                </Col>
                <Col lg="7">
                    <h2>Sobre <span className="fw-bold">Nosotros</span></h2>
                    <p>Somos una agencia/estudio de servicios generales de Diseño Digital, Web, Publicitario y Marketing, con más de 10 años de experiencia, comprometidos con los clientes y con una visión global e integral de la comunicación y el entorno.</p>
                        
                        <p>Contamos con un equipo interdisciplinar con amplia experiencia y formación en las áreas de diseño, desarrollo web, publicidad y estrategias de marketing, que se ajusta a las necesidades de cada proyecto.</p>
                        <p>Nacemos en agosto de 2010 con el nombre de 24cero5 como estudio de diseño gráfico y en 2020 nos convertimos en <span className="fw-bolder color-onbase">85media</span> como agencia integral de comunicación.
                    </p>
                </Col>
            </Row>
        </Container>
    );
}

export default AboutUs;