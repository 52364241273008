import React from 'react';
import { Container } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Row } from "react-bootstrap";

function Dreams() {
    return (
        <div className="bg-dreams d-flex align-items-center">
            <Container>
                <Row>
                    <Col lg="8">
                        <h2 className="lh-1">Hacemos que tus ideas</h2>
                        <h1 className="title-slider">Se vuelvan realidad</h1>
                    </Col>
                    <Col lg="4">
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Dreams; 